// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-menu-template-js": () => import("./../src/templates/menuTemplate.js" /* webpackChunkName: "component---src-templates-menu-template-js" */),
  "component---src-templates-gallery-template-js": () => import("./../src/templates/galleryTemplate.js" /* webpackChunkName: "component---src-templates-gallery-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cakes-js": () => import("./../src/pages/cakes.js" /* webpackChunkName: "component---src-pages-cakes-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cupcakes-js": () => import("./../src/pages/cupcakes.js" /* webpackChunkName: "component---src-pages-cupcakes-js" */),
  "component---src-pages-desserts-js": () => import("./../src/pages/desserts.js" /* webpackChunkName: "component---src-pages-desserts-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-submitted-js": () => import("./../src/pages/submitted.js" /* webpackChunkName: "component---src-pages-submitted-js" */),
  "component---src-pages-terms-js": () => import("./../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

